<template>
  <div>
    <div class='card card-top card-top-primary'>
      <div class='card-header'>
        <div class='d-flex align-items-center justify-content-between'>
          <vb-headers-card-header :data="{ title: 'Ders Paket Detayları' }" />

          <a-tag :color='packageIsPublished ? "success" : "error"'>
            {{ packageIsPublished ? 'Yayında' : 'Yayında Değil' }}
          </a-tag>
        </div>
      </div>

      <div class='card-body'>
        <div class='row'>
          <div class='col-12 col-lg-6'>

            <image-resizer
              :loading='loading'
              :on-complete='uploadPackageCourseCover'
              :initial-image='packageCoverPhoto'
              :max-size='972'
              make-square
              :error-text='"Fotoğraf en az 1000x1000 piksel ölçülerinde kare olmalıdır."'
            />

            <a-form label-align='left' layout='vertical'>
              <a-form-item label='Ders Paketi Adı' name='mainTitle'>
                <a-input v-model:value='packageName' />
                <a-button v-if='showPackageNameSaveButton' @click='updatePackageName' class='btn btn-success mt-2'>
                  Güncelle
                </a-button>
              </a-form-item>

              <a-form-item label='Fiyatı' name='price'>
                <a-input addon-after='₺' v-model:value='packagePrice' v-maska='"######"' />
                <a-tag color='success' v-if='parseInt(packagePrice) === 0' class='mt-2'>
                  Ücretsiz
                </a-tag>
                <a-button v-if='showPackagePriceSaveButton' @click='updatePackagePrice' class='btn btn-success mt-2'>
                  Güncelle
                </a-button>
              </a-form-item>

              <a-form-item label='Sıralaması' name='pcOrder'>
                <a-input v-maska.raw="'########'" placeholder='Sıralaması' v-model:value='pcOrder' />

                <a-button v-if='showPcOrderSaveButton' @click='updatePcOrder'
                          class='btn btn-success mt-2'>Güncelle
                </a-button>
              </a-form-item>
            </a-form>
          </div>
          <div class='col-12 col-lg-6'>
            <a-form label-align='left' layout='vertical'>

              <a-form-item label='App Store Product ID'>
                <a-input v-model:value='packageReferenceName' placeholder='appname_price_duration'>
                  <template #suffix>
                    <a-tooltip title="Revenuecat'de bulunan ürününün 'Identifier' alanı girilmelidir">
                      <info-circle-outlined />
                    </a-tooltip>
                  </template>
                </a-input>

                <a-button v-if='showAppStoreReferenceNameSaveButton' @click='updateAppStoreReferenceName' class='btn btn-success mt-2'>
                  Güncelle
                </a-button>
              </a-form-item>

              <a-form-item label='App Store Fiyatı' name='iosPrice'>
                <a-input addon-after='₺' v-model:value='packageIosPrice' />
                <a-button v-if='showPackageIosPriceSaveButton' @click='updatePackageIosPrice' class='btn btn-success mt-2'>
                  Güncelle
                </a-button>
              </a-form-item>

              <a-form-item label='Açıklama Yazısı' name='packageDescription'>
                <a-textarea v-model:value='packageDescription' />
                <a-button v-if='showPackageDescriptionSaveButton' @click='updatePackageDescription' class='btn btn-success mt-2'>
                  Güncelle
                </a-button>
              </a-form-item>

              <a-form-item label='Video Linki' name='packageVideo'>
                <a-input v-model:value='packageVideo' />
                <a-button v-if='showPackageVideoLinkSaveButton' @click='updateVideoLink' class='btn btn-success mt-2'>
                  Güncelle
                </a-button>
              </a-form-item>

              <a-form-item label='Antrenör' name='broadcastPt'>

                <a-select
                  v-model:value='value'
                  placeholder='Antrenör Seçin'
                  :show-search='true'
                  :show-arrow='true'
                  :filter-option='false'
                  :not-found-content='fetching ? undefined : null'
                  :options='data'
                  :on-popup-scroll='onScrollTrainersDropdown'
                  :loading='fetching'
                  @search='fetchUser'
                  @change='updatePackagePt'
                >
                  <template v-if='fetching' #notFoundContent>
                    <a-spin size='small' />
                  </template>
                </a-select>

              </a-form-item>

            </a-form>
          </div>
        </div>
      </div>
    </div>

    <div class='card card-top card-top-primary'>
      <div class='card-header'>
        <vb-headers-card-header :data="{ title: 'Ders Paketi İşlemleri' }" />
      </div>

      <div class='card-body'>
        <div class='row'>
          <div class='col-12 col-lg-6'>
            <a-popconfirm
              v-if='!packageIsPublished'
              title='Yayınlamak istediğinizden emin misiniz ?'
              ok-text='Evet'
              cancel-text='Hayır'
              @confirm='packagePublish'
            >
              <a-button class='btn btn-success'>Yayına Al</a-button>
            </a-popconfirm>

            <a-popconfirm
              v-if='packageIsPublished'
              title='Yayından kaldırmak isteidiğinize emin misiniz ?'
              ok-text='Evet'
              cancel-text='Hayır'
              @confirm='packagePublish'
            >
              <a-button class='btn btn-danger'>Yayından Kaldır</a-button>
            </a-popconfirm>
          </div>
        </div>
      </div>
    </div>

    <div class='card card-top card-top-primary'>
      <div class='card-header d-flex justify-content-between align-items-center'>
        <vb-headers-card-header :data="{ title: 'Antrenmanlar' }" />
        <a-button class='btn btn-primary btn-with-addon text-nowrap' @click='visibleAddTrainerModal = true'>
          <span class='btn-addon'>
            <i class='btn-addon-icon fe fe-plus-circle'></i>
          </span>
          Yeni Antrenman Ekle
        </a-button>
      </div>

      <div class='card-body'>

        <div class='table-responsive text-nowrap'>
          <a-table
            :columns='columns'
            :data-source='packageCourseTrainings'
            :pagination='false'
            :row-key='(record) => record.id'
            :locale='{emptyText: "Henüz Antrenman Yok"}'
          >

            <template #operation='{ record }'>
              <a-button @click='showTrainingModal(record.id)' class='btn btn-success mr-2'>
                <i class='fe fe-edit-3' />
              </a-button>
              <a-popconfirm
                title='Antrenmanı Silmek İstediğinize Emin Misiniz?'
                ok-text='Evet'
                cancel-text='Hayır'
                @confirm='deleteTrainingFromPackageCourse(record.id)'
              >
                <a-button class='btn btn-danger'>
                  <i class='fe fe-x text white' />
                </a-button>
              </a-popconfirm>
            </template>

          </a-table>
        </div>
      </div>
    </div>

    <a-modal v-model:visible='visibleTrainerModal' centered title='Antrenmanı Düzenle'>
      <template #footer>
        <a-button key='back' @click='visibleTrainerModal = false'>Kapat</a-button>
      </template>

      <!--      <image-resizer-->
      <!--        :loading='loading'-->
      <!--        :on-complete='uploadPackageCoverPhotoOfTraining'-->
      <!--        :initial-image='packageCoverPhotoOfTraining'-->
      <!--        :max-size='1732'-->
      <!--      />-->

      <a-form label-align='left' layout='vertical'>
        <div class='row'>
          <div class='col-12'>
            <a-form-item label='Başlık' name='title'>
              <a-input v-model:value='packageTitleOfTraining' />

              <a-button v-if='showPackageTitleOfTraining' @click='updateTitleOfTraining' class='btn btn-success mt-2'>
                Güncelle
              </a-button>
            </a-form-item>
          </div>
          <div class='col-12'>
            <a-form-item label='Açıklama' name='description'>
              <a-input v-model:value='packageDescriptionOfTraining' />

              <a-button v-if='showPackageDescriptionOfTraining' @click='updateDescriptionOfTraining' class='btn btn-success mt-2'>
                Güncelle
              </a-button>
            </a-form-item>
          </div>
          <div class='col-12'>
            <a-form-item label='Vimeo Video Url' name='trainingVideoLink'>
              <a-input v-model:value='packageVideoLinkOfTraining' />

              <a-button v-if='showPackageVideoLinkOfTraining' @click='updateVideoOfTraining' class='btn btn-success mt-2'>
                Güncelle
              </a-button>
            </a-form-item>
          </div>

        </div>
      </a-form>

      <a-collapse class='mb-2' accordion>
        <a-collapse-panel header='+ Yeni Set'>
          <div class='card-body'>

            <image-resizer
              :loading='loading'
              :on-complete='createCoverPhotoOfTrainingSet'
              :initial-image='coverPhotoOfTrainingSet'
              :max-size='224'
              make-square
              :error-text='"Fotoğraf en az 250x250 piksel ölçülerinde kare olmalıdır."'
            />

            <a-form label-align='left' layout='vertical'>
              <a-form-item label='Set Başlığı' name='setTitle'>
                <a-input v-model:value='titleOfTrainingSet' />
              </a-form-item>
              <a-form-item label='Açıklama Yazısı' name='setSubTitleDescription'>
                <a-textarea v-model:value='descriptionOfTrainingSet' />
              </a-form-item>


              <a-button :loading='loading' @click='createNewSetOfTraining' class='btn btn-success mt-2'>
                Ekle
              </a-button>
            </a-form>
          </div>
        </a-collapse-panel>
      </a-collapse>

      <template v-if='selectedTraining'>
        <a-collapse class='mb-2' accordion>
          <a-collapse-panel v-for='set in selectedTraining.sets' :key='set.id' :header='set.header'>
            <div class='card-body'>
              <image-resizer
                :loading='loading'
                :on-complete='(file) => updateCoverPhotoOfTrainingSet(file, set)'
                :initial-image='set.coverPhoto.coverImage1X'
                :max-size='224'
                make-square
                :error-text='"Fotoğraf en az 250x250 piksel ölçülerinde kare olmalıdır."'
              />

              <a-form label-align='left' layout='vertical'>
                <a-form-item label='Set Başlığı' name='setTitle'>
                  <a-input v-model:value='set.header' />

                  <a-button @click='() => updateTitleOfTrainingSet(set.header, set.id)' class='btn btn-success mt-2'>
                    Güncelle
                  </a-button>
                </a-form-item>
                <a-form-item label='Açıklama Yazısı' name='setSubTitleDescription'>
                  <a-textarea v-model:value='set.subheader' />

                  <a-button @click='() => updateDescriptionOfTrainingSet(set.subheader, set.id)' class='btn btn-success mt-2'>
                    Güncelle
                  </a-button>
                </a-form-item>
              </a-form>
            </div>

            <a-popconfirm
              title='Seti İstediğinize Emin Misiniz?'
              ok-text='Evet'
              cancel-text='Hayır'
              @confirm='deleteSetFromTraining(set.id)'
            >
              <a-button class='btn btn-danger'>
                Seti Sil
              </a-button>
            </a-popconfirm>

          </a-collapse-panel>
        </a-collapse>
      </template>
    </a-modal>

    <a-modal v-model:visible='visibleAddTrainerModal' title='Yeni Antrenman Ekleme' @ok='addTrainingToPackageCourse' centered>

      <template #footer>
        <a-button key='back' @click='visibleAddTrainerModal = false'>İptal</a-button>
        <a-button key='submit' type='submit' class='btn btn-success px-5' :loading='loading' @click='addTrainingToPackageCourse' :disabled='!validateAddTrainingToPackageCourse'>
          Kaydet
        </a-button>
      </template>

      <!--      <image-resizer-->
      <!--        :loading='loading'-->
      <!--        :on-complete='createCoverPhotoOfTraining'-->
      <!--        :initial-image='newPackageTrainingCoverPhoto'-->
      <!--        :max-size='1732'-->
      <!--      />-->

      <a-form
        label-align='left'
        layout='vertical'
      >

        <a-form-item label='Vimeo Video Url' name='weight'>
          <a-input v-model:value='newTrainingVimeoVideoUrl' />
        </a-form-item>
        <a-form-item label='Başlık' name='weight'>
          <a-input v-model:value='newTrainingTitle' />
        </a-form-item>
        <a-form-item label='Açıklama' name='weight'>
          <a-textarea v-model:value='newTrainingDescription' />
        </a-form-item>

      </a-form>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment'
import { useRouter } from 'vue-router'
import { notification } from 'ant-design-vue'
import { debounce } from 'lodash'
import { reactive, toRefs } from 'vue'
import { PackageCourseAdministration, PersonalTrainer } from '../../services/api-service'
import { InfoCircleOutlined } from '@ant-design/icons-vue'

export default {
  name: 'VbDersPaketDetaylar',
  components: {
    InfoCircleOutlined,
  },
  data() {
    const router = useRouter()

    const state = reactive({
      data: [],
      value: [],
      fetching: false,
      searchValue: '',
      pagination: { isNextPageAvailable: false, firstIdOfNextPage: 0 },
    })

    const fetchUser = debounce(async (value) => {
      state.data = []
      state.fetching = true
      state.searchValue = value

      try {
        const { data } = await PersonalTrainer.listPersonalTrainers(undefined, value)
        if (data) {
          state.data = data.personalTrainers.map((pt) => ({
            value: pt.metadataId,
            label: pt.fullName,
          }))

          state.pagination = data.pagination
        }
      } catch {
      }

      state.fetching = false
    }, 300)

    const fetchUserNextPage = debounce(async () => {
      if (state.pagination.isNextPageAvailable) {
        state.fetching = true
        try {
          const { data } = await PersonalTrainer.listPersonalTrainers(state.pagination.firstIdOfNextPage, state.searchValue)
          if (data) {
            state.data = [...state.data, ...data.personalTrainers.map((pt) => ({
              value: pt.metadataId,
              label: pt.fullName,
            }))]

            state.pagination = data.pagination
          }
        } catch {
          state.pagination = { isNextPageAvailable: false, firstIdOfNextPage: 0 }
        }
        state.fetching = false
      }
    }, 300)

    const loadPackageCourseDetails = async () => {
      try {
        const { data } = await PackageCourseAdministration.getPackageCourseDetailsForAdministration(this.packageCourseId)

        if (data) {
          this.packageName = data.name
          this.packageNameFromServer = data.name
          state.value = data.personalTrainer.fullName
          this.packageVideo = data.vimeoVideoInformation.vimeoEmbedUrl
          this.packageVideoFromServer = data.vimeoVideoInformation.vimeoEmbedUrl
          this.packagePrice = data.price.toString()
          this.packagePriceFromServer = data.price.toString()
          this.packageIosPrice = data.iosPrice.toString()
          this.packageIosPriceFromServer = data.iosPrice.toString()
          this.packageDescription = data.description
          this.packageDescriptionFromServer = data.description
          this.packageCoverPhoto = data.coverPhoto.coverImage1X
          this.packageCourseTrainings = data.trainings
          this.packageIsPublished = data.isPublished
          this.packageReferenceName = data.appleProductId
          this.packageReferenceNameFromServer = data.appleProductId
          this.pcOrder = data.pcOrder
          this.pcOrderFromServer = data.pcOrder
        }
      } catch {
        await this.router.push({ path: '/ders-paketleri' })
      }
    }
    const columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        className: 'width-100',
      },
      {
        title: 'Başlık',
        dataIndex: 'header',
      },
      {
        title: 'İçerik',
        dataIndex: 'subheader',
      },
      {
        title: 'Vimeo ID',
        dataIndex: 'vimeoVideoInformation.vimeoVideoId',
      },
      {
        title: 'Set Sayısı',
        dataIndex: 'sets.length',
      },
      {
        title: 'Yönet',
        slots: { customRender: 'operation' },
        class: 'text-right',
      },
    ]

    const createCoverPhotoOfTraining = (file) => {
      this.newPackageTrainingCoverPhoto = file
    }

    const createCoverPhotoOfTrainingSet = (file) => {
      this.coverPhotoOfTrainingSet = file
    }

    const uploadPackageCourseCover = async (file) => {
      this.loading = true

      try {
        const { data } = await PackageCourseAdministration.updateCoverPhotoOfThePackageCourse({ id: this.packageCourseId, coverPhoto: file })
        this.packageCoverPhoto = data.coverPhoto.coverImage1X

        notification.success({
          message: 'Resim başarıyla değiştirildi',
        })
      } catch {
      }

      this.loading = false
    }

    const uploadPackageCoverPhotoOfTraining = async (file) => {
      this.loading = true

      try {
        const { data } = await PackageCourseAdministration.updateCoverPhotoOfTraining({ id: this.selectedTraining.id, coverPhoto: file })
        this.packageCoverPhotoOfTraining = data.coverPhoto.coverImage1X

        notification.success({
          message: 'Resim başarıyla değiştirildi',
        })
      } catch {
      }

      this.loading = false
    }

    const updateCoverPhotoOfTrainingSet = async (file, set) => {
      this.loading = true

      try {
        const { data } = await PackageCourseAdministration.updateCoverPhotoOfTrainingSet({ id: set.id, coverPhoto: file })
        set.coverPhoto = data.coverPhoto

        notification.success({
          message: 'Resim başarıyla değiştirildi',
        })
      } catch {
      }

      this.loading = false
    }

    const showTrainingModal = (id) => {
      this.selectedTraining = this.packageCourseTrainings.find(x => x.id === id)

      if (this.selectedTraining) {
        this.packageCoverPhotoOfTraining = this.selectedTraining.coverPhoto.coverImage1X
        this.packageTitleOfTraining = this.selectedTraining.header
        this.packageTitleOfTrainingFromServer = this.selectedTraining.header
        this.packageDescriptionOfTraining = this.selectedTraining.subheader
        this.packageDescriptionOfTrainingFromServer = this.selectedTraining.subheader
        this.packageVideoLinkOfTraining = this.selectedTraining.vimeoVideoInformation.vimeoEmbedUrl
        this.packageVideoLinkOfTrainingFromServer = this.selectedTraining.vimeoVideoInformation.vimeoEmbedUrl
      }

      this.visibleTrainerModal = true
    }

    return {
      moment,
      router,
      uploadPackageCourseCover,
      uploadPackageCoverPhotoOfTraining,
      updateCoverPhotoOfTrainingSet,
      createCoverPhotoOfTrainingSet,
      createCoverPhotoOfTraining,
      showTrainingModal,
      loading: false,
      trainersLoading: false,
      columns,

      packageCourseId: undefined,
      packageName: '',
      packageNameFromServer: '',
      packageVideo: '',
      packageVideoFromServer: '',
      packagePrice: '',
      packagePriceFromServer: '',
      packageIosPrice: '',
      packageIosPriceFromServer: '',
      packageReferenceName: '',
      packageReferenceNameFromServer: '',
      packageDescription: '',
      packageDescriptionFromServer: '',
      packageCoverPhoto: '',
      pcOrder: '',
      pcOrderFromServer: '',

      packageCoverPhotoOfTraining: '',
      packageTitleOfTraining: '',
      packageTitleOfTrainingFromServer: '',
      packageDescriptionOfTraining: '',
      packageDescriptionOfTrainingFromServer: '',
      packageVideoLinkOfTraining: '',
      packageVideoLinkOfTrainingFromServer: '',
      packageIsPublished: false,

      titleOfTrainingSet: '',
      titleOfTrainingSetFromServer: '',
      descriptionOfTrainingSet: '',
      descriptionOfTrainingSetFromServer: '',
      coverPhotoOfTrainingSet: '',
      coverPhotoOfTrainingSetFromServer: '',

      packageCourseTrainings: [],
      visibleTrainerModal: false,
      visibleAddTrainerModal: false,
      selectedTraining: null,

      newTrainingTitle: '',
      newTrainingDescription: '',
      newTrainingVimeoVideoUrl: '',

      trainers: [],

      newPackageTrainingCoverPhoto: '',

      loadPackageCourseDetails,
      ...toRefs(state),
      fetchUser,
      fetchUserNextPage,
    }
  },
  computed: {
    showPackageNameSaveButton() {
      return this.packageNameFromServer !== this.packageName
    },
    showPackagePriceSaveButton() {
      return this.packagePriceFromServer !== this.packagePrice
    },
    showPackageIosPriceSaveButton() {
      return this.packageIosPriceFromServer !== this.packageIosPrice
    },
    showAppStoreReferenceNameSaveButton() {
      return this.packageReferenceNameFromServer !== this.packageReferenceName
    },
    showPackageDescriptionSaveButton() {
      return this.packageDescriptionFromServer !== this.packageDescription
    },
    showPackageDescriptionOfTraining() {
      return this.packageDescriptionOfTraining !== this.packageDescriptionOfTrainingFromServer
    },
    showPackageTitleOfTraining() {
      return this.packageTitleOfTraining !== this.packageTitleOfTrainingFromServer
    },
    showPackageVideoLinkOfTraining() {
      return this.packageVideoLinkOfTraining !== this.packageVideoLinkOfTrainingFromServer
    },
    showPackageVideoLinkSaveButton() {
      return this.packageVideoFromServer !== this.packageVideo
    },
    showPcOrderSaveButton() {
      return this.pcOrderFromServer !== this.pcOrder
    },
    validateAddTrainingToPackageCourse() {
      return this.newTrainingDescription !== '' && this.newTrainingVimeoVideoUrl !== '' && this.newTrainingVimeoVideoUrl !== ''
    },
  },
  mounted() {
    this.packageCourseId = parseInt(this.$route.params['id'])
    this.loadPackageCourseDetails()
    this.fetchUser('')
  },
  methods: {
    fallback(event) {
      event.target.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEX49/zLys7BwMP8+//19Pnc29/U09fNzNDs6+/k4+jf3uLIx8vr6u/08/jw7/TPztKoqKmZVIr2AAADW0lEQVR4nO3d23KjMBBFUbBBMoiL//9rJ45dMaBugVMD6kPOfpoH4mIVF0ngZIr75dzdi8v13F2+hMWZoxA/CvGjED8K8aMQPwrxoxA/CvGjED8K8aMQPwrxoxA/CvGjED8K8aMQPwrxoxA/CvGjED8K8aMQPwrxoxA/CvGjEL/PhU2dvWFfoQtl5kK3szA3sAw3CimkMHcUUkghhWcThoPKJnTNQflMwtAetPKqxmzCj37091GYiMI4CneJwkQUxlG4SxQmojCOwl2iMJEFYd/U4zi6NvHCBVpYteNzBR+8U41mhcP6e7B++i7La2+VrArHEOqVT6umjye+UohGhY+js3aUoyd38lG3KazD+u400etWJ25nUti+dn6s9G1mO/5K3H2LwuZnm8SleBPemIubGxR2k31v1M9qBaGXNrQnHGb7rI4Z0huCIJ3V5oT9/PqS7x6FIuyFDa0Jq8U3UoJ2nsIewzq6vJTztBaEpbShLeE1BmpDRifcacRT2pYwHsVLZQxYXq/fifM2U8Kb4Cu1ISMeLuzPaQbhsDzy0i1SmNTIU29DQmkeljo4/Xwj7a5rSKi/5Vfuu8M4PVG1YcWOULiNvpN3rK/9a41fAqzxpXnmO6esMobWjd67OvG1UStCaXibbp1YZVSJNVZhRtglfWVi9raaDeHgl6I4ccjYkAmhPk5MUlcZK5kQbvqG+2/PUwvC5Dgx6aO9+8mAMD1OTEo9mNLLL2yWEL3EkDGoN6LsQmU9Iab+PkjnvK87+RDnFvYbxolJ4pG6Pe9UYWwNPqeR1rGphCGje9+JQ3DxUc4sHD78Tahovt4tVyTj8l0imLD0s12MfI8PXlyRaMLpkHHTZgqzKxJOWNbXFd/3p7+vSDzhc8joVmd6wT+vSDzh48GUdP0JRgcqLN3W39uAFW6OQgoppJBCCimkkEIKKaSQQgoppJBCCin8L9l4Xur2rDYgPCAKE1EY97eEZVtUh9RnE/qjKnMJs0QhhRRSSCGFFFJI4Qbh+8+lZvrH3n9X/5jFRLKP9pf/vwV+FOJHIX4U4kchfhTiRyF+FOJHIX4U4kchfhTiRyF+FOJHIX4U4kchfhTiRyF+FOJHIX4U4kchfhTiRyF+FOJHIX5/RHjuLsX9cu7u/wBN6mvsVsNSZwAAAABJRU5ErkJggg=='
    },
    async updatePackageName() {
      try {
        await PackageCourseAdministration.updateNameOfThePackageCourse({ name: this.packageName, id: this.packageCourseId })
        this.packageNameFromServer = this.packageName
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Adı Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePackagePrice() {
      try {
        await PackageCourseAdministration.updatePriceOfThePackageCourse({ price: this.packagePrice, id: this.packageCourseId })
        this.packagePriceFromServer = this.packagePrice
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Ücreti Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePackageIosPrice() {
      try {
        //TODO
        await PackageCourseAdministration.updateIosPriceOfThePackageCourse({ iosPrice: this.packageIosPrice, id: this.packageCourseId })
        this.packageIosPriceFromServer = this.packageIosPrice
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Ios Ücreti Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateAppStoreReferenceName() {
      try {
        await PackageCourseAdministration.updateAppleProductIdOfThePackageCourse({ newAppleProductId: this.packageReferenceName, id: this.packageCourseId })
        this.packageReferenceNameFromServer = this.packageReferenceName
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'App Store Product ID Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePackageDescription() {
      try {
        await PackageCourseAdministration.updateDescriptionOfThePackageCourse({
          description: this.packageDescription,
          id: this.packageCourseId,
        })
        this.packageDescriptionFromServer = this.packageDescription
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Açıklaması Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePackagePt(val) {
      try {
        await PackageCourseAdministration.updatePersonalTrainerOfThePackageCourse({
          id: this.packageCourseId,
          personalTrainerId: val,
        })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Eğitmeni Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateVideoLink() {
      try {
        await PackageCourseAdministration.updatePromotionalVimeoUrlOfThePackageCourse({
          id: this.packageCourseId,
          newPromotionalVideoUrl: this.packageVideo,
        })
        this.packageVideoFromServer = this.packageVideo
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Video Linki Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePcOrder() {
      try {
        await PackageCourseAdministration.updatePcOrderOfThePackageCourse({
          id: this.packageCourseId,
          pcOrder: this.pcOrder,
        })
        this.packageOrderFromServer = this.pcOrder
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Paket Sıralaması Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async packagePublish() {
      try {
        await PackageCourseAdministration.updatePublishStateOfThePackageCourse({
          id: this.packageCourseId,
          publicState: !this.packageIsPublished,
        })

        this.packageIsPublished = !this.packageIsPublished

        notification.success({
          message: 'İşlem Başarılı!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateTitleOfTraining() {
      try {
        await PackageCourseAdministration.updateTitleOfTraining({ id: this.selectedTraining.id, title: this.packageTitleOfTraining })
        this.packageTitleOfTrainingFromServer = this.packageTitleOfTraining
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman Başlığı Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateDescriptionOfTraining() {
      try {
        await PackageCourseAdministration.updateDescriptionOfTraining({ id: this.selectedTraining.id, description: this.packageDescriptionOfTraining })
        this.packageDescriptionOfTrainingFromServer = this.packageDescriptionOfTraining
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman Açıklaması Başarıyla Değiştirildi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateVideoOfTraining() {
      try {
        await PackageCourseAdministration.updateVimeoVideoUrlOfTheTraining({ id: this.selectedTraining.id, vimeoVideoUrl: this.packageVideoLinkOfTraining })
        this.packageVideoLinkOfTrainingFromServer = this.packageVideoLinkOfTraining
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman video Linki Başarıyla Güncellendi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateTitleOfTrainingSet(val, setId) {
      try {
        await PackageCourseAdministration.updateTitleOfTrainingSet({ id: setId, title: val })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman Set Başlığı Başarıyla Güncellendi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updateDescriptionOfTrainingSet(val, setId) {
      try {
        await PackageCourseAdministration.updateDescriptionOfTrainingSet({ id: setId, title: val })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman Set Açıklaması Başarıyla Güncellendi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async deleteTrainingFromPackageCourse(id) {
      try {
        await PackageCourseAdministration.deleteTrainingFromPackageCourse({ id })

        this.packageCourseTrainings = this.packageCourseTrainings.filter(x => x.id !== id)

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman Paketi Eğitimi Başarıyla Silindi!',
        })

      } catch (e) {
        console.log(e)
      }
    },
    async deleteSetFromTraining(id) {
      try {
        await PackageCourseAdministration.deleteSetFromTraining({ id })

        this.selectedTraining = this.selectedTraining.sets.filter(x => x.id !== id)

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman Paketi Eğitimi Seti Başarıyla Silindi!',
        })

      } catch (e) {
        console.log(e)
      }
    },
    async addTrainingToPackageCourse() {
      this.loading = true
      try {
        await PackageCourseAdministration.addTrainingToPackageCourse({
          // coverPhoto: this.newPackageTrainingCoverPhoto,
          coverPhoto: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOEAAADhCAMAAAAJbSJIAAAAM1BMVEX49/zLys7BwMP8+//19Pnc29/U09fNzNDs6+/k4+jf3uLIx8vr6u/08/jw7/TPztKoqKmZVIr2AAADW0lEQVR4nO3d23KjMBBFUbBBMoiL//9rJ45dMaBugVMD6kPOfpoH4mIVF0ngZIr75dzdi8v13F2+hMWZoxA/CvGjED8K8aMQPwrxoxA/CvGjED8K8aMQPwrxoxA/CvGjED8K8aMQPwrxoxA/CvGjED8K8aMQPwrxoxA/CvGjEL/PhU2dvWFfoQtl5kK3szA3sAw3CimkMHcUUkghhWcThoPKJnTNQflMwtAetPKqxmzCj37091GYiMI4CneJwkQUxlG4SxQmojCOwl2iMJEFYd/U4zi6NvHCBVpYteNzBR+8U41mhcP6e7B++i7La2+VrArHEOqVT6umjye+UohGhY+js3aUoyd38lG3KazD+u400etWJ25nUti+dn6s9G1mO/5K3H2LwuZnm8SleBPemIubGxR2k31v1M9qBaGXNrQnHGb7rI4Z0huCIJ3V5oT9/PqS7x6FIuyFDa0Jq8U3UoJ2nsIewzq6vJTztBaEpbShLeE1BmpDRifcacRT2pYwHsVLZQxYXq/fifM2U8Kb4Cu1ISMeLuzPaQbhsDzy0i1SmNTIU29DQmkeljo4/Xwj7a5rSKi/5Vfuu8M4PVG1YcWOULiNvpN3rK/9a41fAqzxpXnmO6esMobWjd67OvG1UStCaXibbp1YZVSJNVZhRtglfWVi9raaDeHgl6I4ccjYkAmhPk5MUlcZK5kQbvqG+2/PUwvC5Dgx6aO9+8mAMD1OTEo9mNLLL2yWEL3EkDGoN6LsQmU9Iab+PkjnvK87+RDnFvYbxolJ4pG6Pe9UYWwNPqeR1rGphCGje9+JQ3DxUc4sHD78Tahovt4tVyTj8l0imLD0s12MfI8PXlyRaMLpkHHTZgqzKxJOWNbXFd/3p7+vSDzhc8joVmd6wT+vSDzh48GUdP0JRgcqLN3W39uAFW6OQgoppJBCCimkkEIKKaSQQgoppJBCCin8L9l4Xur2rDYgPCAKE1EY97eEZVtUh9RnE/qjKnMJs0QhhRRSSCGFFFJI4Qbh+8+lZvrH3n9X/5jFRLKP9pf/vwV+FOJHIX4U4kchfhTiRyF+FOJHIX4U4kchfhTiRyF+FOJHIX4U4kchfhTiRyF+FOJHIX4U4kchfhTiRyF+FOJHIX5/RHjuLsX9cu7u/wBN6mvsVsNSZwAAAABJRU5ErkJggg==',
          id: this.packageCourseId,
          title: this.newTrainingTitle,
          description: this.newTrainingDescription,
          vimeoVideoUrl: this.newTrainingVimeoVideoUrl,
        })

        this.newTrainingTitle = ''
        this.newTrainingDescription = ''
        this.newTrainingVimeoVideoUrl = ''

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman Paketi Eğitimi Başarıyla Eklendi!',
        })

        await this.loadPackageCourseDetails()

        this.visibleAddTrainerModal = false

      } catch {
      }
      this.loading = false
    },
    async createNewSetOfTraining() {
      this.loading = true
      try {
        const { data } = await PackageCourseAdministration.addSetToTraining({
          id: this.selectedTraining.id,
          coverPhoto: this.coverPhotoOfTrainingSet,
          title: this.titleOfTrainingSet,
          description: this.descriptionOfTrainingSet,
        })

        this.selectedTraining.sets = [...this.selectedTraining.sets, {
          id: data.id,
          coverPhoto: data.coverPhoto,
          header: this.titleOfTrainingSet,
          subheader: this.descriptionOfTrainingSet,
        }]

        this.coverPhotoOfTrainingSet = ''
        this.titleOfTrainingSet = ''
        this.descriptionOfTrainingSet = ''

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenman Paketi Eğitimi Seti Başarıyla Eklendi!',
        })

        await this.loadPackageCourseDetails()

      } catch {
      }
      this.loading = false
    },
    async onScrollTrainersDropdown(event) {
      const target = event.target
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.fetchUserNextPage()
      }
    },
  },
}
</script>
